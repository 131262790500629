package main.kotlin.app.foodees.web.components.atoms.searchbar

import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.LinearDimension
import main.kotlin.app.foodees.web.components.MColor
import main.kotlin.app.foodees.web.components.atoms.button.mIconButton
import main.kotlin.app.foodees.web.components.atoms.divider.mDivider
import main.kotlin.app.foodees.web.components.atoms.input.mInput
import main.kotlin.app.foodees.web.components.atoms.paper.mPaper
import main.kotlin.app.foodees.web.icons.mDirectionsIcon
import main.kotlin.app.foodees.web.icons.mMenuIcon
import main.kotlin.app.foodees.web.icons.mSearchIcon
import react.*
import styled.StyleSheet
import styled.css

class SearchBar : RComponent<RProps, RState>() {
    private object ComponentStyles : StyleSheet("ComponentStyles", isStatic = true) {
        val root by css {
            padding = "2px 4px"
            display = Display.flex
            alignItems = Align.center
            width = LinearDimension("80%")
            marginLeft = LinearDimension("auto")
            marginRight = LinearDimension("auto")
            marginTop = LinearDimension("-2em")
        }

        val input by css {
            marginLeft = LinearDimension("8")
            flexGrow = 1.0
        }

        val iconButton by css {
            padding = "10"
        }

        val divider by css {
            width = LinearDimension("1")
            height = LinearDimension("28")
            margin = "4"
        }
    }

    override fun RBuilder.render() {
        mPaper(className = "root", elevation = 1) {
            css(ComponentStyles.root)

            mIconButton(className = "iconButton") {
                css(ComponentStyles.iconButton)

                mMenuIcon()
            }

            mInput(className = "input", placeholder = "Search Foodees") {
                css(ComponentStyles.input)
            }

            mIconButton(className = "iconButton") {
                css(ComponentStyles.iconButton)

                mSearchIcon()
            }

            mDivider(className = "divider") {
                css(ComponentStyles.divider)
            }

            mIconButton(className = "iconButton", color = MColor.primary) {
                css(ComponentStyles.iconButton)

                mDirectionsIcon()
            }
        }
    }
}

fun RBuilder.mSearchBar() = child(SearchBar::class) {}
