package main.kotlin.app.foodees.web.components.atoms.icon

import kotlinext.js.JsObject
import kotlinx.html.HTMLTag
import kotlinx.html.HtmlBlockInlineTag
import kotlinx.html.TagConsumer
import kotlinx.html.attributesMapOf
import main.kotlin.app.foodees.web.components.MColor
import main.kotlin.app.foodees.web.components.createStyled
import main.kotlin.app.foodees.web.components.setStyledPropsAndRunHandler
import react.RBuilder
import react.RComponent
import react.RState
import react.ReactElement
import react.dom.RDOMBuilder
import react.dom.tag
import styled.StyledHandler
import styled.StyledProps

open class PATH(initialAttributes: Map<String, String>, override val consumer: TagConsumer<*>) : HTMLTag("path", consumer, initialAttributes, null, false, false), HtmlBlockInlineTag {}

inline fun RBuilder.path(d: String? = null, fill: String? = null, block: RDOMBuilder<PATH>.() -> Unit): ReactElement = tag(block) { PATH(attributesMapOf("d", d, "fill", fill), it) }

@JsModule("@material-ui/core/SvgIcon")
private external val iconModule: dynamic

@Suppress("UnsafeCastFromDynamic")
private val iconComponent: RComponent<MSvgIconProps, RState> = iconModule.default

@Suppress("EnumEntryName")
enum class MSvgIconColor {
    inherit, primary, secondary, action, error, disabled
}

@Suppress("EnumEntryName")
enum class MSvgIconFontSize {
    inherit, default, small, large
}

interface MSvgIconProps : StyledProps {
    var color: String
    var fontSize: String
    var viewBox: String
    var style: JsObject
}

fun RBuilder.mSvgIcon(
        iconName: String,
        primary: Boolean = false, // If true, then this overrides the color... just an easier setter...
        color: MSvgIconColor? = null,
        fontSize: MSvgIconFontSize = MSvgIconFontSize.default,
        viewBox: String = "0 0 24 24",

        addAsChild: Boolean = true,
        className: String? = null,
        handler: StyledHandler<MSvgIconProps>? = null) = createStyled(iconComponent, addAsChild) {
    if (primary) {
        attrs.color = MColor.primary.toString()
    } else {
        color?.let { attrs.color = color.toString() }
    }

    attrs.fontSize = fontSize.toString()

    attrs.viewBox = viewBox

    childList.add(iconName)

    setStyledPropsAndRunHandler(className, handler)
}
