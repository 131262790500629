package main.kotlin.app.foodees.web.components.atoms.logo

import react.*
import react.dom.*

@JsModule("src/main/kotlin/app/foodees/web/components/atoms/logo/react.svg")
external val reactLogo: dynamic
@JsModule("src/main/kotlin/app/foodees/web/components/atoms/logo/kotlin.svg")
external val kotlinLogo: dynamic

fun RBuilder.logo(height: Int = 100) {
    div("Logo") {
        attrs.jsStyle.height = height
        img(alt = "React logo", src = reactLogo, classes = "Logo-react") {}
        img(alt = "Kotlin logo", src = kotlinLogo, classes = "Logo-kotlin") {}
    }
}
