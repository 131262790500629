package main.kotlin.app.foodees.web.templates.app

import react.*
import react.dom.*
import main.kotlin.app.foodees.web.components.atoms.logo.*
import main.kotlin.app.foodees.web.components.atoms.searchbar.mSearchBar
import main.kotlin.app.foodees.web.components.atoms.ticker.*

class App : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("App-header") {
            logo()

            h2 {
                +"Foodees with React and Kotlin!"
            }
        }

        p("test") {
            mSearchBar()
        }

        p("App-atoms.ticker") {
            ticker()
        }
    }
}

fun RBuilder.app() = child(App::class) {}
