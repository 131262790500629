package main.kotlin.app.foodees.web.icons

import main.kotlin.app.foodees.web.components.atoms.icon.mSvgIcon
import main.kotlin.app.foodees.web.components.atoms.icon.path
import react.*

fun RBuilder.mMenuIcon() {
    mSvgIcon("menu") {
        path(d = "M0 0h24v24H0z", fill = "none") {}
        path(d = "M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z") {}
    }
}
